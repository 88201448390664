import Hero from "../hero/hero";
import StoreFront from "../storefront/storeFront";
import Reviews from "../reviews/reviews";
import React, {useEffect, useState} from "react";
import {getData} from "../../utils/customHooks/customHooks";
import {useDispatch, useSelector} from "react-redux";
import {selectScroll, setScroll} from "../../store/authSlice/scrollSlice";
import {setCartLength} from "../../store/authSlice/cartSlice";
import WhyUs from "../why-us/why-us";
import SeoBlock from "../seoBlock/seoBlock";
import CategoriesOnHomePage from "../categoriesOnHomePage/categoriesOnHomePage";
import ContinueShopingModal from "../continueShopingModal/continueShopingModal";
import {Helmet} from "react-helmet";
import BlogPreview from "../blog/BlogPreview";
import Faq from "../faq/faq";


function Homepage({setCountLikedProducts,block1,block2}) {

    const scrollBlock = useSelector(selectScroll);
    const dispatch = useDispatch();
    const [showContinueShopingModal , setShowContinueShopingModal] = useState(false);

    useEffect(()=>{
        if(scrollBlock.scroll__id){
            const block = document.getElementById(`${scrollBlock.scroll__id}`);
            block.scrollIntoView({ behavior: "smooth" ,block:"start"});
            setTimeout(()=>{
                dispatch(setScroll({
                    scroll__id :null
                }))
            },5000)
        }
    })

    useEffect(() => {

        getData("/api/basket/get_data_from_basket/").then(res => {
            dispatch((setCartLength({
                "cart_length": res.len_basket
            })))
        })
    }, [])

    return (
        <div>
            {showContinueShopingModal? <ContinueShopingModal showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}/>:''}
            <Helmet>
                <title>Mur Mur Flowers</title>
            </Helmet>
            <Hero/>
            <CategoriesOnHomePage/>
            {block1 &&
                <StoreFront setCountLikedProducts={setCountLikedProducts} showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}  products={block1.product} block={block1}/> }
            {block2 &&
                <StoreFront setCountLikedProducts={setCountLikedProducts} showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal} products={block2.product} block={block2}/>}

            <WhyUs/>
            <Reviews/>
            <BlogPreview></BlogPreview>
            <Faq is_main={true}></Faq>
            <SeoBlock apiUrl={"/api/get_seo_block_main/"}/>
        </div>
    );
}

export default Homepage;