import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {BsArrowLeft} from "react-icons/bs"
import {MdKeyboardArrowRight, MdOutlineReviews} from "react-icons/md";
import "./reviews.css";
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import {useTranslation} from "react-i18next";
import '../../language/i18n';
import {getData} from "../../utils/customHooks/customHooks";
import RewiewsRatingStar from "./rewiewsRatingStar";
import {Link} from "react-router-dom";

function Reviews() {
    const {t} = useTranslation();
    const [comments, setComments] = useState([]);
    const lang = JSON.parse(localStorage.getItem("language"));

    const getAllComments = () => {
        getData('/api/main_reviwed/').then(res => {
            setComments(res)
        })
    }
    useEffect(()=>{
        getAllComments();
    },[])

    return (
        <>
            {comments && comments.length!==0 &&
                <div className="reviews" id="reviews">
                    <div className="reviews__container container">
                        <div className="reviews__header">
                            <h2 className="reviews__title main__title container"> {t('main_reviews')}</h2>
                            <div className="reviews__header__btn ">
                                <Link to= {`/${lang}/reviews`} className="storefront__btn__link ">
                                    {t("all_reviews")}
                                </Link>
                                <MdKeyboardArrowRight />
                            </div>
                        </div>


                        <div className="reviews__swiper__container ">
                            <Swiper
                                loop={true}
                                speed={1000}
                                pagination={true}
                                modules={[Pagination]}
                                slidesPerView={3}
                                spaceBetween={20}
                                className="mySwiper reviews__swiper container"
                            >
                                {comments.map((comment, index) => (
                                    <SwiperSlide className="reviews__swiper__slide" key={index}>
                                        <div className="reviews__swiper__slide__content">
                                            <p className="reviews__author-name">
                                                {comment.username}
                                            </p>
                                            <RewiewsRatingStar comment={comment} index={index}/>
                                            <p className="reviews__text-comment">
                                                {comment.text}
                                            </p>

                                        </div>
                                    </SwiperSlide>
                                ))
                                }

                            </Swiper>

                        </div>

                    </div>
                </div>
            }


        </>
    );
}

export default Reviews;