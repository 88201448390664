import React, {useEffect, useState} from 'react';
import {LuHeart, LuShoppingBag, LuShoppingCart} from "react-icons/lu";
import {getData, postData} from "../../utils/customHooks/customHooks";
import {setLikedLength} from "../../store/authSlice/likedProducts";
import {setCartLength} from "../../store/authSlice/cartSlice";
import {useDispatch} from "react-redux";
import "../productPage/productPage.css";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.css';

const FlowersLikeTo = ({flowersList,setShowContinueShopingModal, isAddtProduct=false, isBlog=true}) => {
    const lang = JSON.parse(localStorage.getItem("language"));
    const [currentIncrease, setCurrentIncrease] = useState(1);
    const {t} = useTranslation();

    const dispatch = useDispatch();

    // const addProductToLiked = (e) => {
    //     postData(`/api/like/add_like_to_product/`, {
    //         product: product.id,
    //     }).then(res => {
    //         if (res.status === 200) {
    //             getData("/api/like/get_like_to_product").then(res => {
    //                 if (res !== []) {
    //                     dispatch((setLikedLength({
    //                         "liked_length": res.length
    //                     })))
    //                 }
    //             })
    //             if (e.target.className.baseVal.includes("liked")) {
    //                 e.target.classList.remove('liked');
    //             } else {
    //                 e.target.classList.add('liked');
    //             }
    //         }
    //     })
    // }

    const addToCart = (id) => {
        if(!isAddtProduct) {
            postData("/api/basket/add_to_basket/", {
                product: id,
                size: currentIncrease,
                quantity: 1
            })
        }else {
            postData("/api/basket/add_to_basket/", {
                additional_product: id,
                size: currentIncrease,
                quantity: 1
            })
        }
        getData("/api/basket/get_data_from_basket/").then(res => {
            dispatch((setCartLength({
                "cart_length": res.len_basket
            })))
        })
    }
    useEffect(() => {
        console.log(flowersList)
    }, [flowersList])
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 30, width: '100%', margin: '40px 0'}}>
            {!isAddtProduct &&
                <div style={{fontSize: 35,}}>{t("you_would_like")}</div>
            }
            <Swiper
                slidesPerView={'auto'}
                style={{width: '100%'}}
                className="mySwiper"
                spaceBetween={15}
            >
                {flowersList.map((flower, index) => (
                    <SwiperSlide className={'you_could_like_to'}>
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%'}}>
                            <Link to={!isAddtProduct?`/${lang}/flower-page/${flower.id}/${flower.slug}`:`/${lang}/additional-product/${flower.id}`} style={{ color:'black' }}>
                                <div style={{ width: '100%', position: 'relative', aspectRatio: '1 / 1' }}>
                                    {isAddtProduct
                                        ? <img
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                            src={flower.photo}
                                            alt={flower.name}
                                        />
                                        : <img
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                            src={flower.photo.main_photo}
                                            alt={flower.name}
                                        />}
                                    {!isAddtProduct && <div className="heart-icon">
                                        <LuHeart/>
                                    </div>}

                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 20 }}>{flower.price} {t("persona_cabinet_user_order_currency")}</div>
                                    <LuShoppingBag style={{ fontSize: 20 }} onClick={() => {
                                    addToCart(flower.id);
                                    setShowContinueShopingModal(true)
                                }}/>
                                </div>
                                <div style={{ fontSize: 14 }}>{lang === "en" ? flower.name_eng : flower.name}</div>
                            </Link>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
};

export default FlowersLikeTo;