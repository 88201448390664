import React from 'react';
import { useState, useEffect } from 'react';
import "./blog.css";
import {FaRegEye} from "react-icons/fa";
import flowerImage from '../../assets/images/img_3678.jpg';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {getData, postData} from "../../utils/customHooks/customHooks";
import {Link, useParams} from "react-router-dom";
import {setCartLength} from "../../store/authSlice/cartSlice";
import admin_a from "../../assets/images/admin_a.png"
import {LuAlarmClock, LuEye, LuFacebook, LuHourglass, LuInstagram, LuMail, LuPhoneCall} from "react-icons/lu";
import {setCategories} from "../../store/authSlice/categoriesSlice";
import FlowersLikeTo from "./flowers_like_to";
import ContinueShopingModal from "../continueShopingModal/continueShopingModal";


function BlogCategory() {
    const lang = JSON.parse(localStorage.getItem("language"));
    const [productsTop5, setProductsTop5] = useState([])
    const { slug } = useParams();

    const [blogCategoryList, setBlogCategoryList] = useState([])
    const [categoryDetail, setCategoryDetail] = useState({})
    const [showContinueShopingModal , setShowContinueShopingModal] = useState(false);


    useEffect(() => {
        postData("/api/blog/category/", { slug })
            .then(res => {
                setCategoryDetail(res.data.category)
                setProductsTop5(res.data.featured_product)
        })
    }, [slug])
    useEffect(() => {
        getData("/api/blog/categories/").then(res => {
            setBlogCategoryList(res)
        })
    }, [])
    useEffect(() => {
        console.log(categoryDetail)
    }, [categoryDetail])
    if (!blogCategoryList && !categoryDetail) {
        return <div>Loading...</div>;
    }

    return (
        <>
                        {showContinueShopingModal? <ContinueShopingModal showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}/>:''}

            <div className="blog">
                <div className="blog__container container">
                    <div>
                        Головна > <Link to={`/${lang}/blog/`} style={{color: 'black'}}>Блог</Link> > <Link
                        to={`/${lang}/blog/category/${slug}`} style={{color: 'black'}}>{categoryDetail.title}</Link>
                    </div>
                    <div className="blog__title">
                        Блог
                    </div>
                    <div className="blog__categories">
                        {blogCategoryList.length > 0 && (
                            <>
                                {blogCategoryList.map((article) => (
                                    <Link to={`/${lang}/blog/category/${article.slug}`}>
                                        <div className="blog__categories__item" key={article.id}>
                                            {article.title}
                                        </div>
                                    </Link>
                                ))}
                            </>
                        )}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 30,}}>
                        <div className="category_name" style={{marginTop: 30}}>{categoryDetail.title}</div>
                        {blogCategoryList.length > 0 && (
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{300: 1, 560: 2, 1400: 3}}
                            >

                                <Masonry
                                    columnsCount={3} gutter="30px"
                                    className="blog__row__masonry"
                                >
                                    {categoryDetail.articles.map((article) => (
                                        <Link to={`/${lang}/blog/${article.slug}`} style={{color: 'black'}}>
                                            <div className="blog__row__masonry__item">
                                                <div className="blog__row__masonry__item__img__box"
                                                     style={{height: `221px`}}>
                                                    <img src={article.img} alt=""
                                                         className="blog__row__masonry__item__img"/>
                                                </div>
                                                <div className="blog__row__masonry__item__content">
                                                    <h3 className="blog__row__item__title">
                                                        {article.name}
                                                    </h3>
                                                    <div className="hr-with-text">
                                                            <span
                                                                style={{color: "#EC5E5A"}}>{article.category.title}</span>
                                                    </div>
                                                    <p className="blog__row__item__text">
                                                        {article.description} <span
                                                        style={{color: "#EC5E5A"}}>...читати</span>
                                                    </p>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={{display: "flex", gap: 10}}>
                                                            <img src={admin_a} alt="logo" className="admin_logo"/>
                                                            <div>
                                                                <p style={{
                                                                    fontSize: "17px",
                                                                    fontWeight: "bold"
                                                                }}>{article.article_author.first_name} {article.article_author.last_name}</p>
                                                                <p>квітковий експерт</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{display: "flex", alignItems: 'center', gap: 5}}>
                                                            <LuEye style={{color: "#EC5E5A"}}/>
                                                            {article.views}
                                                        </div>
                                                        <div
                                                            style={{display: "flex", alignItems: 'center', gap: 5}}>
                                                            <LuHourglass style={{color: "#EC5E5A"}}/>
                                                            {article.reading_time}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        )}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#EC5E5A0D',
                            padding: '25px 20px',
                            width: '90%'
                        }}>
                            {productsTop5 &&
                                <FlowersLikeTo setShowContinueShopingModal={setShowContinueShopingModal}
                                               flowersList={productsTop5}></FlowersLikeTo>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogCategory;