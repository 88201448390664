import React, {useState} from 'react';
import {CiSquarePlus} from "react-icons/ci";
import {CiSquareMinus} from "react-icons/ci";
import {AnimatePresence, motion} from 'framer-motion';
import { IoIosArrowDown } from "react-icons/io";

function FaqQuestion({status , question, answer}) {
    const [isDropdownVisible, setDropdownVisible] = useState(status?true:false);

    const showFaq = () => {

        setDropdownVisible(!isDropdownVisible);
    }

    return (
        <div>
            <div className="faq__row__item" onClick={showFaq}>
                <div className="faq__row__item__title">
                    {question} <IoIosArrowDown  className={`faq__row__item_arrow ${(isDropdownVisible) ? "active" : ""} `}/>
                </div>
                {isDropdownVisible && <motion.div className="faq__row__item__dropdown "

                                                  initial={{opacity: 0, y: -10}}
                                                  animate={{opacity: 1, y: 0}}
                                                  transition={{duration: .2}}
                                                  dangerouslySetInnerHTML={{__html: answer}}
                />
                }
            </div>

        </div>
    );
}

export default FaqQuestion;